<template>
  <Layout mainStyle="background: #E5E7EB;">
    <section class="box-container">
      <div class="grid grid-cols-2 gap-10">
        <div>
          <h4 class="font-inter text-left">
            {{ $t('label.app.common.country') }}
          </h4>

          <div
            class="list-container list-arrow bg-white text-left text-light-gray mt-5 p-3"
          >
            <div class="relative">
              <input
                type="search"
                placeholder="Seacrh"
                @input="onCountryFilterChange"
                v-model="search"
              />
            </div>

            <div class="list font-inter font-normal text-large leading-8">
              <ul>
                <li
                  v-for="(country, index) in initCountries"
                  :key="index"
                  class="break-words hover:text-black cursor-pointer"
                  :class="{ 'bg-gray-200': country.code == activeCountry.code }"
                  @click.prevent="setCountryActive(country)"
                  @dblclick.prevent="saveCountry(country)"
                >
                  {{ slice(country.name) }}
                </li>
              </ul>
            </div>

            <div class="grid grid-cols-2 gap-8">
              <BaseButton
                :text="$t('text.app.common.add')"
                kind="default"
                classes="bg-app-purple text-white my-3 hover:bg-app-purple ml-0"
                style="
                  margin-left: 0;
                  color: white;
                  font-size: 16px;
                  letter-spacing: 0.2ch;
                  width: 100%;
                "
                @click.prevent="saveCountry(null)"
                :disabled="loading || fetching"
                :class="{ 'cursor-not-allowed': loading || fetching }"
              >
                <font-awesome-icon
                  icon="angle-double-right"
                  class="fa-fw fa-lg mx-1"
                />
              </BaseButton>

              <BaseButton
                :text="$t('text.app.common.add-all')"
                kind="default"
                classes="bg-app-purple text-white my-3 hover:bg-app-purple ml-0"
                style="
                  margin-left: 0;
                  color: white;
                  font-size: 16px;
                  letter-spacing: 0.2ch;
                  width: 100%;
                "
                @click.prevent="saveAllCountriesHandle"
                :disabled="loading || fetching"
                :class="{ 'cursor-not-allowed': loading || fetching }"
              >
                <font-awesome-icon
                  icon="angle-double-right"
                  class="fa-fw fa-lg mx-1"
                />
              </BaseButton>
            </div>
          </div>
        </div>

        <div>
          <div class="flex justify-between">
            <h4 class="font-inter text-left items-center">
              {{ $t('label.app.common.your_list') }}
            </h4>

            <BaseButton
              :text="$t('text.app.common.save')"
              kind="default"
              classes="bg-app-purple text-white hover:bg-app-purple"
              style="color: white; font-size: 16px; letter-spacing: 0.2ch"
              @click.prevent="saveCountries"
              :disabled="loading || fetching"
              :class="{ 'cursor-not-allowed': loading || fetching }"
            >
              <font-awesome-icon
                icon="circle-notch"
                class="fa-spin"
                v-show="loading"
              />
            </BaseButton>
          </div>

          <div
            class="list-container bg-white text-left text-light-gray mt-3 p-3"
          >
            <div class="relative">
              <input
                type="search"
                placeholder="Seacrh"
                @input="onMyCountryFilterChange"
                v-model="mySearch"
              />
            </div>

            <div class="list">
              <ul>
                <template v-if="favCountries.length">
                  <li
                    v-for="(country, index) in favCountries"
                    :key="index"
                    class="break-words hover:text-black cursor-pointer"
                    :class="{
                      'bg-gray-200': country.code == activeYourCountry.code
                    }"
                    @click.prevent="setYourCountryActive(country)"
                    @dblclick.prevent="removeCountry(country)"
                  >
                    {{ slice(country.name) }}
                  </li>
                </template>
                <template v-else>
                  <li
                    class="break-words text-gray-500 font-normal font-inter text-sm"
                  >
                    {{ $t('label.widget.security.no-countries') }}.
                  </li>
                </template>
              </ul>
            </div>

            <div class="grid grid-cols-2 gap-8">
              <BaseButton
                :text="$t('text.app.common.remove')"
                kind="default"
                classes="bg-app-purple text-white my-3 hover:bg-app-purple ml-0"
                style="
                  margin-left: 0;
                  color: white;
                  font-size: 16px;
                  letter-spacing: 0.2ch;
                  width: 100%;
                "
                @click.prevent="removeCountry(null)"
                :disabled="loading || fetching"
                :class="{ 'cursor-not-allowed': loading || fetching }"
              />

              <BaseButton
                :text="$t('text.app.common.remove-all')"
                kind="default"
                classes="bg-app-purple text-white my-3 hover:bg-app-purple ml-0"
                style="
                  margin-left: 0;
                  color: white;
                  font-size: 16px;
                  letter-spacing: 0.2ch;
                  width: 100%;
                "
                @click.prevent="removeAllCountries"
                :disabled="loading || fetching"
                :class="{ 'cursor-not-allowed': loading || fetching }"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
</template>

<script setup>
import lodash from 'lodash'
import { useStore } from 'vuex'
import { onMounted, ref, computed, watch, inject } from 'vue'
import mainSection from '@/modules/widget/components/home/main-section'
import Layout from '@/modules/widget/components/home/Layout/Index'
import useWidgetConfig from '@/modules/widget/composables/useWidgetConfig'
import MainSection from '../../../virtualnum/components/home/main-section'
import useCountries from '../../composables/widget/useCountries'
import useHelpers from '@/composables/useHelpers'
import useSecurityService from '../../composables/widget/useSecurityService'
import useToaster from '@/composables/useToaster'

const t = inject('t')
const store = useStore()
const { countries } = useCountries()
const { slice } = useHelpers()
const { getContacts, toggleWidget } = useWidgetConfig()
const { fetchMyCountryData, saveNewCountry, saveAllCountries, fetching } =
  useSecurityService()
const { trigger } = useToaster()

const initCountries = ref([])
const favCountries = ref([])
const initialCountries = ref([])
const yourCountries = ref([])
const search = ref('')
const mySearch = ref('')
const activeCountry = ref('')
const activeYourCountry = ref('')

const loading = computed(() => {
  const { id, value } = store.getters['widget/loading']
  return id == 'save-countries' && value
})

const activeWidgetId = computed(() => store.getters['widget/activeWidgetId'])

const setCountryActive = country => {
  activeCountry.value = country
}

const setYourCountryActive = country => {
  activeYourCountry.value = country
}

const onCountryFilterChange = e => {
  const search = e.target.value

  if (search === '') {
    const favCountriesArray = favCountries.value.map(element => element.code)
    const filteredCountries = countries.value.filter(
      country => !favCountriesArray.includes(country.code)
    )
    initCountries.value = [...filteredCountries]
  } else {
    initCountries.value = initCountries.value.filter(i =>
      i.name.toLowerCase().includes(search.toLowerCase())
    )
  }
}

const onMyCountryFilterChange = e => {
  const search = e.target.value

  if (search === '') {
    const initCountriesArray = initCountries.value.map(element => element.code)
    const filteredCountries = countries.value.filter(
      country => !initCountriesArray.includes(country.code)
    )
    favCountries.value = [...filteredCountries]
  } else {
    favCountries.value = favCountries.value.filter(i =>
      i.name.toLowerCase().includes(search.toLowerCase())
    )
  }
}

const saveCountry = (country = null) => {
  if (loading.value || fetching.value) return

  if (country != null) activeCountry.value = country

  if (activeCountry.value == '' || activeCountry.value == null) return

  favCountries.value.push(activeCountry.value)

  initCountries.value = initCountries.value.filter(
    country => country.code !== activeCountry.value.code
  )

  activeCountry.value = ''
}

const saveAllCountriesHandle = () => {
  if (loading.value || fetching.value) return

  const allCountries = [...initCountries.value, ...favCountries.value]

  initCountries.value = []
  favCountries.value = allCountries
  activeCountry.value = ''
}

const removeAllCountries = () => {
  if (loading.value || fetching.value) return

  const allCountries = [...initCountries.value, ...favCountries.value]

  favCountries.value = []
  initCountries.value = allCountries
  activeYourCountry.value = ''
}

const removeCountry = (country = null) => {
  if (loading.value || fetching.value) return

  if (country != null) activeYourCountry.value = country

  if (activeYourCountry.value == '' || activeYourCountry.value == null) return

  initCountries.value.push(activeYourCountry.value)

  favCountries.value = favCountries.value.filter(
    country => country.code !== activeYourCountry.value.code
  )

  activeYourCountry.value = ''
}

const saveCountries = async () => {
  if (loading.value || fetching.value) return

  try {
    const myFavouritesData = favCountries.value.map(country => country.code)
    await saveAllCountries(myFavouritesData)
    search.value = ''
    mySearch.value = ''
    initCountries.value = countries.value.filter(
      country => !myFavouritesData?.includes(country.code)
    )
    trigger({
      message: t('validation.widget.pages.security'),
      type: 'success'
    })
  } catch (e) {
    trigger({
      message: t('valiation.pages.common.something_went_wrong'),
      type: 'danger'
    })

    throw e
  }
}

const fetchMyCountryList = async () => {
  yourCountries.value = []
  favCountries.value = []

  initialCountries.value = countries.value

  let fetchResult = await fetchMyCountryData()
  const list = countries.value.filter(country =>
    fetchResult?.includes(country.code)
  )

  initCountries.value = countries.value.filter(
    country => !fetchResult?.includes(country.code)
  )
  yourCountries.value = list
  favCountries.value = list
}

watch(activeWidgetId, value => {
  if (value != -1) fetchMyCountryList()
})

onMounted(() => {
  fetchMyCountryList()
  getContacts()
  toggleWidget(true)
})
</script>

<style scoped lang="scss">
h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #9b9bc5;
}

.box-container {
  padding: 30px 20px 10px 20px;

  .list-container {
    // height: 440px;
    height: calc(100vh - 190px);

    input {
      border: 0;
      outline: 0;
      border-bottom: 1px solid #ccc;
      box-sizing: border-box;
      width: 100%;
      margin: 0.4rem 0 1rem 0;
    }

    .list {
      height: 75%;

      @media only screen and (min-height: 700px) {
        height: 85%;
      }

      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(26, 26, 26, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid rgb(76, 87, 97);
      }
    }
  }

  .list-arrow {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: -77px;
      transform: rotate(90deg) translate(-50%);
      width: 0;
      height: 0;
      border: solid 40px;
      border-color: transparent transparent white transparent;
    }
  }
}
</style>
