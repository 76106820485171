<template>
  <Layout>
    <div class="bg-gray-200 h-full overflow-auto relative">
      <div class="flex flex-row item-center justify-center m-8">
        <div
          class="flex-1 flex flex-row justify-between bg-white rounded-md m-2 hover:bg-gray-100 cursor-pointer"
          @click.prevent="$router.push({ name: 'security-country' })"
        >
          <p class="text-lg font-bold py-5 px-4">COUNTRY</p>
          <img
            class="px-2 py-6 card-image"
            src="@/assets/images/widget/security-country.svg"
            alt=""
          />
        </div>

        <div
          class="flex-1 flex flex-row justify-between bg-white rounded-md m-2 hover:bg-gray-100 cursor-pointer"
          @click.prevent="$router.push({ name: 'security-blacklist' })"
        >
          <p class="text-lg font-bold py-5 px-4">BLACKLISTED IP</p>
          <img
            class="px-2 py-6 card-image"
            src="@/assets/images/widget/security-blacklist.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import { onMounted } from 'vue'
import mainSection from '@/modules/widget/components/home/main-section'
import Layout from '@/modules/widget/components/home/Layout/Index'
import useWidgetConfig from '@/modules/widget/composables/useWidgetConfig'

const { getContacts, toggleWidget } = useWidgetConfig()

onMounted(() => {
  getContacts()
  toggleWidget(true)
})
</script>

<style lang="scss" scoped>
.card-image {
  width: 163px;
  height: 163px;
}
</style>
