import api from "@/services/api";
import { useStore } from "vuex";
import { ref, reactive, computed, watch } from "vue";
import authenticate from "@/composables/auth/authenticate";
import { SAVE_COUNTRIES, SAVE_BLACKLIST_IPS } from "../../store/types";

const fetching = ref(false);

export default function useSecurityService() {
  const store = useStore();
  const { getAuth: auth } = authenticate();

  const saveNewCountry = async (param_new_country) => {
    return await api.put("/admin/widget/countries", {
      country_code: param_new_country,
    });
  };

  const activeWidgetId = computed(() => store.getters["widget/activeWidgetId"]);

  const saveAllCountries = async (countries) => {
    return store.dispatch(`widget/${SAVE_COUNTRIES}`, countries);
  };

  const fetchMyCountryData = async () => {
    if (activeWidgetId.value == -1) return;
    fetching.value = true;
    const { data } = await api.get(
      `/admin/widget/countries/${activeWidgetId.value}`
    );
    fetching.value = false;
    return Promise.resolve(data);
  };

  const deleteMyCountry = async (country_code) => {
    return await api.delete(`/admin/widget/countries/${country_code}`);
  };

  const saveMyBlackIPAddress = async (ips) => {
    const { id, value } = store.getters["widget/loading"];
    if (id == "save-ips" && value) return;
    return store.dispatch(`widget/${SAVE_BLACKLIST_IPS}`, ips);
  };

  const fetchMyBlackIPList = async () => {
    if (activeWidgetId.value == -1) return;
    const { data } = await api.get(
      `/admin/widget/blacklist/${activeWidgetId.value}`
    );
    return Promise.resolve(data);
  };

  const deleteBlackIPAddress = async (list_id) => {
    return await api.delete(
      `/admin/widget/blacklist/${activeWidgetId.value}/${list_id}`
    );
  };

  const sendInfoMsgRabbit = async () => {
    const user_id = reactive(store.state.profilestore.personal_info);
    return await api.post("/admin/widget/security-countryinfo", {
      userID: user_id,
    });
  };

  const sendIPInfoMsgRabbit = async () => {
    return await api.post("/admin/widget/security-blackipinfo", {
      widget_id: activeWidgetId.value,
    });
  };

  return {
    fetching,
    saveAllCountries,
    deleteBlackIPAddress,
    fetchMyBlackIPList,
    saveMyBlackIPAddress,
    deleteMyCountry,
    saveNewCountry,
    fetchMyCountryData,
    sendInfoMsgRabbit,
    sendIPInfoMsgRabbit,
  };
}
