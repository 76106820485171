<template>
  <Layout>
    <div
      class="panel-section flex flex-row items-start justify-around bg-gray-200 overflow-auto"
    >
      <div class="flex-1 flex flex-col">
        <p
          class="w-full text-app-purple text-2xl font-bold text-left pt-6 pb-2 px-10"
        >
          TYPE IP
        </p>
        <div
          class="country-list-section flex flex-col justify-start bg-white my-4 mx-10 rounded-sm"
        >
          <div class="w-full flex flex-row items-center justify-center p-4">
            <label class="text-xl text-gray-500 pb-8">IP:</label>
            <div
              class="form-group pb-3 pl-2 pr-8 w-full flex flex-col items-start justify-center"
            >
              <input
                type="text"
                id="type_ip"
                name="type_ip"
                placeholder="000.000.000.000"
                v-model="type_ip"
                class="block border-1 border-b-half border-light-green w-full focus:outline-none placeholder-gray-500 text-lg mx-2"
              />
              <label for="type_ip" class="text-sm text-left text-gray-500 px-2"
                >Type ID to blacklist</label
              >
            </div>
          </div>
          <div
            class="btn-addnew flex flex-row justify-center items-center btn-order-new bg-app-purple h-10 m-1 p-1.5 text-white cursor-pointer hover:text-gray-500"
            style="border-radius: 4px"
            @click.prevent="saveBlackIPAddress()"
          >
            <font-awesome-icon
              icon="angle-double-right"
              class="fa-fw fa-lg mx-1"
            />
            <p class="ml-1 text-xl">Add</p>
          </div>
        </div>
      </div>
      <div class="arrow-section"></div>
      <div class="flex-1 flex flex-col">
        <div class="w-full flex flex-row justify-between items-center">
          <p
            class="w-full text-app-purple text-2xl font-bold text-left pt-6 pb-2 px-10"
          >
            YOUR BLACKLIST
          </p>
          <div
            class="btn-addnew flex flex-row justify-center items-center btn-order-new bg-app-purple h-10 m-1 p-1.5 mt-3 mr-5 text-white cursor-pointer hover:text-gray-500"
            style="border-radius: 4px"
            @click.prevent="saveMyBlackIPAddress(blacklist)"
          >
            <p class="ml-1 text-xl w-20">Save</p>
            <font-awesome-icon
              icon="circle-notch"
              class="fa-spin"
              v-show="loading"
            />
          </div>
        </div>
        <div
          class="country-list-section flex flex-col justify-start bg-white my-4 mx-10 rounded-sm"
        >
          <div class="flex flex-row items-center justify-center p-4">
            <input
              class="h-10 w-32 md:w-32 lg:w-64 xl:w-80 2xl:w-96"
              placeholder="Search"
              v-model="searchRight"
              style="border: solid 1px lightgrey; border-radius: 4px"
              @keydown="onBlackIPFilterChange()"
            />
            <font-awesome-icon
              icon="search"
              class="fa-fw fa-lg mx-1 cursor-pointer hover:text-purple-200"
              style="margin-left: -40px"
            />
          </div>
          <div class="list_card text-left p-4">
            <ul>
              <li
                v-for="(item, index) in blacklist"
                :key="item"
                class="text-xl font-semibold px-6 rounded-sm hover:bg-gray-100 cursor-pointer"
                :class="{ active: itemActive[index] }"
                @click="selectIPListItem(index)"
                @dblclick="DeleteBlackIPItem()"
              >
                {{ item }}
              </li>
            </ul>
          </div>
          <div
            class="btn-addnew flex flex-row justify-center items-center btn-order-new bg-app-purple h-10 m-1 p-1.5 text-white cursor-pointer hover:text-gray-500"
            style="border-radius: 4px"
            @click.prevent="DeleteBlackIPItem()"
          >
            <p class="ml-1 text-xl">Remove</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import { useStore } from 'vuex'
import { onMounted, ref, computed, watch } from 'vue'
import mainSection from '@/modules/widget/components/home/main-section'
import Layout from '@/modules/widget/components/home/Layout/Index'
import useWidgetConfig from '@/modules/widget/composables/useWidgetConfig'
import useSecurityServiceService from '@/modules/widget/composables/widget/useSecurityService'
import countries from 'countries-list'
import { type } from 'os'

const store = useStore()
const { getContacts, toggleWidget } = useWidgetConfig()
const {
  saveMyBlackIPAddress,
  fetchMyBlackIPList,
  deleteBlackIPAddress,
  sendIPInfoMsgRabbit
} = useSecurityServiceService()
const blacklist = ref([])
const searchRight = ref('')
const type_ip = ref('')
const itemActive = ref([])
const currentItemIndex = ref(0)

const activeWidgetId = computed(() => store.getters['widget/activeWidgetId'])

const loading = computed(() => {
  const { id, value } = store.getters['widget/loading']
  return id == 'save-ips' && value
})

const saveBlackIPAddress = async () => {
  let checkIPResult = ValidateIPaddress(type_ip.value)
  if (checkIPResult == true) {
    blacklist.value.push(type_ip.value)
    type_ip.value = ''
    // let saveResult = await saveMyBlackIPAddress(type_ip.value)
  } else {
    alert('You have entered an invalid IP address!')
  }
}

const fetchMyBlackIPData = async () => {
  blacklist.value = await fetchMyBlackIPList()
}

const SaveInfoServer = async () => {
  let saveIPInfoResult = await sendIPInfoMsgRabbit()
}

const selectIPListItem = async item_index => {
  for (let i = 0; i < blacklist.value.length; i++) {
    itemActive.value[i] = false
  }
  itemActive.value[item_index] = !itemActive.value[item_index]
  currentItemIndex.value = item_index
}

const DeleteBlackIPItem = async () => {
  let deleteResult = await deleteBlackIPAddress(currentItemIndex.value)
  blacklist.value = [...deleteResult.data]
}

const onBlackIPFilterChange = () => {
  if (searchRight.value == '') {
    fetchMyBlackIPData()
  } else {
    const result = filterList(blacklist.value, searchRight.value)
    let templist = []
    for (let i = 0; i < result.length; i++) {
      templist.push(result[i])
    }
    blacklist.value = templist
  }
}

function filterList (list, keyword) {
  let keywordParam = `(${keyword.trim()})`
  var noProxyData = [...list]
  if (keywordParam == null) {
    return []
  }
  return noProxyData.filter(item => {
    const re = new RegExp(keywordParam, 'gi')
    const result = re.test(item)
    return result
  })
}

const ValidateIPaddress = ipaddress => {
  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipaddress
    )
  ) {
    return true
  }
  return false
}

watch(activeWidgetId, value => {
  if (value != -1) fetchMyBlackIPData()
})

onMounted(() => {
  getContacts()
  fetchMyBlackIPData()
  toggleWidget(true)
})
</script>

<style scoped>
.list_card {
  height: auto;
  overflow-y: auto;
}
.panel-section {
  overflow-y: hidden;
  position: relative;
  height: calc(100vh - 56px);
}

.arrow-section {
  margin-left: -40px;
  margin-top: 120px;
  transform: rotate(90deg);
  width: 0;
  height: 0;
  border: solid 40px;
  border-color: transparent transparent white transparent;
}

.list_card li.active {
  --tw-bg-opacity: 0.5;
  background-color: rgba(155, 155, 197, var(--tw-bg-opacity));
  color: black;
}

::-webkit-input-placeholder {
  padding: 5px !important;
}
</style>
